// import { ref } from "vue"; 
// import config from '../../config'
const getNews = async (id) => {
  let restults = {};
  // const axios = require("axios"); 
  //   try { 
  //     restults = await axios.get("https://www.cadt.edu.kh/wp-json/wp/v2/posts/?per_page="+id+"&_fields=author,id,excerpt,title,_links&_embed&_links")
  //      .then(res =>res.data);
  //   } catch (err) { 
  //     // error.value = err.message; 
  //   }  
  return { restults, id }
}
export default getNews