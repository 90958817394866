<template>
  <div class="container">
    <div class="logo-10">
      <img src="../assets/CADT-10.png" alt="">
    </div>
    <div class="row main-box" :ref="picture">
      <swiper :modules="modules" :autoplay="{ delay: 500, disableOnInteraction: true }" :loop="true"
        :slides-per-view="1">
        <!-- <swiper-slide v-for="a in announcements" :key="a.id" :data-swiper-autoplay="a.duration"> 
         <div class="slide slide-m">
          <img :src="'https://udms.cadt.edu.kh'+a.source" alt="Sample 1">
         </div> 
      </swiper-slide> -->
        <swiper-slide v-for="a in announcements" :key="a.id" :data-swiper-autoplay="a.duration">

          <div class="slide slide-m">
            <video v-if="a.type == 'vdo'" style="width:100%;" :src="'https://udms.cadt.edu.kh' + a.source"
              preload="auto" autoplay="1" muted></video>
            <img v-if="a.type == 'image'" :src="'https://udms.cadt.edu.kh' + a.source" alt="Image">
          </div>
        </swiper-slide>

      </swiper>
    </div>
    <div class="bottom-right">
      <h1 class="text-center">Our Lecturer</h1>
      <swiper :modules="modules" :autoplay="{ delay: 500, disableOnInteraction: true }" :loop="true"
        :slides-per-view="1">
        <swiper-slide v-for="l in lecturers" :key="l.id" data-swiper-autoplay="6000">
          <div class="box-lect">
            <img :src="'https://udms.cadt.edu.kh' + l.photo" alt="Sample 1">
            <h3>{{ l.name }}</h3>

            <h6>{{ l.position }}</h6>
            <p>{{ str_limit(l.short_description, 320) }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div>
      <div class="bottom-box">
        <div class="box-logo bg-idt-color">
          <h3 class="text-center text-white cadt-text our-partner">Our Partners</h3>

          <swiper :modules="modules" :autoplay="{ delay: 3000, disableOnInteraction: true }" :loop="true"
            :slides-per-view="1">
            <swiper-slide v-for="p in partners" :key="p.id">
              <img :src="'https://udms.cadt.edu.kh' + p.logo" alt="Sample 1">
            </swiper-slide>
          </swiper>

        </div>
        <div class="box-title bg-color-idg">
          <h2 class="title cadt-text">Our Event</h2>
          <p class="cadt-text time-date">{{ currentTime.toLocaleDateString("en-US", opttimeOptionions) }} <span
              class="cadt-text time"> {{ currentTime.toLocaleTimeString() }}</span></p>
        </div>
        <div class="box-detial bg-color-idri text-center">
          <div class="text-detial text-center">
            <h3 class="cadt-text text-center">Celebrating 10 Years of Nurturing Digital Talents and Innovation</h3>
          </div>
          <!--    <marquee class="text-detial" behavior="scroll" direction="left" scrollamount="10">
                 <template v-for="n  in news" :key="n.id">
                   <B><u>{{n.title.rendered}}:</u></B>
                 {{strippedContent(n.excerpt.rendered)}}
                 </template>
</marquee>
-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { ref } from "vue";
import getNews from "../composable/getNews";
import { getPartners, getLectures, getAnnouncements } from "../composable/udmsAPI";
import { useCurrentTime } from "../composable/useCurrentTime";
import { Pagination, Autoplay, Navigation, EffectCube } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

// import swiper module styles
import "swiper/css";
import "swiper/css/pagination";
// more module style...
export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      toastMessage: ""
    };
  },
  mounted() {
    setInterval(() => {
      location.reload();
    }, (1000 * 60 * 10)); // reload every 10 seconds
    this.showToast(
      "Page updated Successfully at " + new Date().toLocaleTimeString() + "!"
    );
  },
  methods: {
    str_limit(value, size) {
      if (!value) return '';
      value = value.toString();

      if (value.length <= size) {
        return value;
      }
      return value.substr(0, size) + '... See More';
    },
    showToast(message) {
      this.toastMessage = message;
      setTimeout(() => {
        this.toastMessage = "";
      }, 5000); // hide the toast after 5 seconds
    },
    hideToast() {
      this.toastMessage = "";
    },
    activate(ms, i) {
      console.log("i:" + i);
      setTimeout(() => (this.isHidden = false), ms);
      return false;
    },

    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    }
  },

  setup() {
    function anotherRandomFunction() {
      // this.$ref.btn.$el.click()
      // elem.click()
    }
    anotherRandomFunction();

    let partners = ref("");
    let lecturers = ref("");
    let announcements = ref("");

    let news = ref("");

    let getService = async () => {
      const data = await getNews(4);
      const partnerData = await getPartners();
      const lecturersData = await getLectures();
      const announcementsData = await getAnnouncements();

      news.value = data.restults;
      partners.value = partnerData.data;
      lecturers.value = lecturersData.data;
      announcements.value = announcementsData.data;
    };
    getService();
    const { currentTime } = useCurrentTime();
    var timeOption = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };

    // this.swiper.autoplay.start();
    return {
      announcements,
      news,
      partners,
      currentTime,
      timeOption,
      Pagination,
      modules: [Pagination, Navigation, Autoplay, EffectCube],
      lecturers
    };
  }
};
</script>

<style>
body {
  margin: 0px !important;
}

* {
  padding: 0px;
  margin: 0px;
}

.container {
  widows: 100vw;
  height: 100vh;
  background: #13284c;
}

.main-box {
  position: absolute;
  background: #13284c;
  width: 80vw;
  height: 80vh;
}

.text-center {
  text-align: center;
}

.bottom-right {
  background: #13284c;
  width: 20%;
  height: 80vh;
  position: absolute;
  right: 0;
  z-index: 2;
}

.bottom-box {
  background: white;
  height: 20vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  z-index: 3;
}

.box-logo {
  width: 15%;
  height: 100%;
  position: absolute;
  left: 0px;
  text-align: center;
}

.our-partner {
  font-size: 2vh;
}

.box-title {
  width: 85%;
  height: 60%;
  position: absolute;
  right: 0;
  top: 0;
  display: grid;
  align-items: center;
  justify-items: start;
}

.box-detial {
  width: 85%;
  height: 40%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: grid;
  align-items: center;
  justify-items: start;
}

.bg-idt {
  background: linear-gradient(230deg,
      rgba(0, 255, 81, 0) 5%,
      rgb(249 142 43) 5%);
}

.bg-idt-color {
  background: rgb(249 142 43)
}

.bg-idg {
  background: linear-gradient(230deg,
      rgba(0, 255, 81, 0) 5%,
      rgb(0, 205, 237) 5%);
}

.bg-idri {
  background: linear-gradient(230deg,
      rgba(0, 255, 81, 0) 5%,
      rgb(224, 0, 0) 5%);
}

.bg-color-idg {
  background: rgb(0, 211, 226);
}

.bg-color-idri {
  background: rgb(224, 0, 0);
}

.slide-news {
  width: 100%;
  height: 100%;
  z-index: 4;
}

.slide-m img {
  width: 100%;
}

.title {
  margin: 10px;
  text-align: left;
  font-size: 3.5vh;
}

.box-logo img {
  width: 55%;
}

.text-detial {
  font-size: 2.5vh;
  color: white;
}

.marquee {
  height: 25px;
  width: 420px;

  overflow: hidden;
  position: relative;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;

  position: absolute;
  overflow: hidden;

  animation: marquee 5s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

.text-center {
  text-align: center;
}

.box-lect {
  width: 100%;
  text-align: center;
}

.box-lect h3 {
  color: white;
  font-size: 2vh;
}

.box-lect h6 {
  color: rgb(245, 167, 0);
  font-size: 2vh;
  padding: 0px;
}

.box-lect p {
  color: white;
  font-size: 1.9vh;
  text-align: justify;
  padding: 10px;
}

.box-lect img {
  width: 80%;
  text-align: center;
}

:not(i),
label,
p,
span {
  font-family: Niradei;
}

.bottom-right h1 {
  color: white;
  font-size: 3vh;
  font-family: "CADT Mono Display";
  margin-top: 20px;
}

.cadt-text {
  font-family: "CADT Mono Display";
}

.agile__slides {
  align-items: flex-start;
  display: flex;
}

.clock {
  position: fixed;
  z-index: 2;
  right: 0px;
  bottom: 8vh;
  text-align: center;
  display: grid;
  align-items: center;
  /* background:linear-gradient(230deg,rgba(0, 255, 81,0) 5%,rgb(237, 142, 0,50%) 5%);*/
  padding: 20px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 6vh;
}

.time-date {
  color: #13284c;
  float: right;
  text-align: right;
  position: absolute;
  right: 100px;
  font-size: 6vh;
}

Swiper {
  width: 100%;
  height: 100%;
}

img {
  background: transparent !important;
}

.text-white {
  color: white;
}

.logo-10 {
  position: absolute;
  top: 20px;
  z-index: 20;
  left: 20px;
}
</style>