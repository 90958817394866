 const  getLectures = async () => {
    let data = {}; 
    const axios = require("axios"); 
      try { 
        data = await axios.get("https://udms.cadt.edu.kh/api/get_lecturers")
         .then(res =>res.data.lecturers);
      } catch (err) { 
        // error.value = err.message; 
      }  
    return {data}
}
const getPartners = async () => {
    let data = {}; 
    const axios = require("axios"); 
      try { 
        data = await axios.get("https://udms.cadt.edu.kh/api/get_partners")
         .then(res =>res.data.partners);
      } catch (err) { 
        // error.value = err.message; 
      }  
    return {data}
} 
const getAnnouncements = async () => {
    let data = {}; 
    const axios = require("axios"); 
      try { 
        data = await axios.get("https://udms.cadt.edu.kh/api/get_announcements")
         .then(res =>res.data.data);
      } catch (err) { 
        // error.value = err.message; 
      }  
    return {data}
} 
export {getLectures,getPartners,getAnnouncements}